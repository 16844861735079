<script>
  import merge from 'lodash/merge'
  import Report from './Report'

  export default {
    name: 'extension',
    extends: Report,
    props: ['entityName', 'id', 'extraQuery'],
    computed: {
      prop() {
        return this.entityName.slice(0, -1) + '_id'
      },
      getData() {
        let extraQuery = {}

        if (this.entityName) {
          extraQuery = merge(
            {
              filters: {
                [this.prop]: this.id || this.$route.params['id'],
                alias: this.entityName,
              },
            },
            this.extraQuery || {}
          )
        }

        return {
          entity: 'intervals',
          path: 'intervals/daily',
          predefined: {
            with: [
              'worth',
              'cost',
              'rate',
              'userRate',
              'remainder',
              'margin',
              'task',
              'user',
              'user.employee',
              'project',
              'time_detail',
              'project.employee_statistic_total',
            ],
            extraQuery,
          },
        }
      },
    },
    methods: {
      readyCallback() {
        this.$emit('loaded')
      },
    },
    created() {
      let entityFilter = this.filters.findIndex(filter => {
        return filter.name === this.prop
      })

      if (entityFilter + 1) {
        this.filters.splice(entityFilter, 1)
      }
    },
    beforeDestroy() {
      this.$store.dispatch('entities/resetEntity', 'intervals').then(() => {})
    },
  }
</script>
